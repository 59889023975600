import { Container, Heading, SimpleGrid } from '@chakra-ui/react';
import ServiceCard from "./serviceCard";
import {FaCheckCircle, FaCode, FaGlobe, FaMobileAlt} from "react-icons/fa";

function Services() {
    return (
        <Container py={20} px={{ base: 18, md: 28 }} width={"100%"} maxW={"100%"} centerContent background='brandBlack.300'>
            <Heading>Our Services</Heading>
            <SimpleGrid w='100%' pt={10} columns={{ base: 1, sm: 1, lg: 2 }} columnGap={10} rowGap={10}>
                <ServiceCard
                    name="Custom Software Development"
                    description="Tailor-made software solutions designed to address specific business needs and enhance operational efficiency."
                    icon={<FaCode/>}
                />
                <ServiceCard
                    name="Web Application Development"
                    description="Building interactive and scalable web applications to provide seamless user experiences and facilitate online interactions."
                    icon={<FaGlobe/>}
                />
                <ServiceCard
                    name="Mobile App Development"
                    description="Crafting intuitive and feature-rich mobile applications that cater to diverse platforms and user preferences."
                    icon={<FaMobileAlt/>}
                />
                <ServiceCard
                    name="Quality Assurance and Testing"
                    description="Implementing rigorous testing methodologies to ensure software reliability, performance, and adherence to standards."
                    icon={<FaCheckCircle/>}
                />
            </SimpleGrid>
        </Container>
    );
}

export default Services;
