import { extendTheme } from '@chakra-ui/react';

const config = {
    initialColorMode: 'light',
    useSystemColorMode: false,
    colors: {
        brand: {
            50: "#E6F9FC",
            100: "#CDF3FA",
            500: "#07C6EA",
            600: "#06B2D2",
            700: "#059EBB",
            800: "#048AA3",
            900: "#04768C"
        },
        brandBlack: {
            100: "#FFFFFF",
            300: "#F7F7F7",
            500: "#EBEBEB",
            700: "#717171",
            900: "#222222"
        }
    }
};

const customTheme = extendTheme(config);

export default customTheme;
