import { Container, Heading, SimpleGrid } from '@chakra-ui/react';
import TestimonialCard from './testimonialCard';

function Testimonials() {
    return (
        <Container py={20} px={{ base: 18, md: 28 }} width={"100%"} maxW={"100%"} centerContent background='brandBlack.900'>
            <Heading color={'white'}>Testimonials</Heading>
            <SimpleGrid w='100%' pt={10} columns={1} columnGap={20} rowGap={10}>
                <TestimonialCard name="MSME Business Founder"
                                 description="Devspark has been exceptional in delivering our project on time and within budget. Their team's technical expertise and communication made the whole process smooth."
                />
                <TestimonialCard name="Tech Startup CEO"
                                 description="Working with Devspark has been a pleasure. They understood our requirements perfectly and exceeded our expectations."
                />
                <TestimonialCard name="Augmented Reality Company CTO"
                                 description="Devspark exceeded our expectations in every way. Their team's technical expertise and dedication to delivering top-notch solutions were remarkable. They truly listened to our requirements and transformed our vision into a powerful reality. Working with Devspark was an absolute pleasure, and we look forward to collaborating with them again."
                />
                <TestimonialCard name="Digital Marketing Agency Director"
                                 description="We had a complex software project, and Devspark proved to be the perfect partner. Their meticulous attention to detail during the development process and their commitment to delivering a flawless product were commendable. The communication throughout the project was outstanding, and they kept us updated at every stage. We highly recommend Devspark to anyone seeking reliable and innovative software solutions."
                />
            </SimpleGrid>
        </Container>
    );
}

export default Testimonials;
