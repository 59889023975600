import { Button, Container, FormControl, FormLabel, Heading, Input, VStack } from '@chakra-ui/react';

function ContactForm() {
    return (
        <Container id={'scheduleDemo'} py={20} px={{ base: 18, md: 28 }} width={"100%"} maxW={"100%"} centerContent background='#FFFFFF'>
            <Heading>Contact Us</Heading>

            <VStack mt={'10'} w={{ base: '100%', md: '50%' }} alignItems={'center'}>
                <FormControl mb={1}>
                    <FormLabel>Full Name</FormLabel>
                    <Input type='name' />
                </FormControl>
                <FormControl mb={1}>
                    <FormLabel>Business Name</FormLabel>
                    <Input type='category' />
                </FormControl>
                <FormControl mb={1}>
                    <FormLabel>Business Category</FormLabel>
                    <Input type='subcategory' />
                </FormControl>
                <FormControl mb={1}>
                    <FormLabel>Email Address</FormLabel>
                    <Input type='email' />
                </FormControl>
                <FormControl mb={1}>
                    <FormLabel>Phone Number</FormLabel>
                    <Input type='phone' />
                </FormControl>
                <FormControl>
                    <Button colorScheme='brand'>
                        Submit
                    </Button>
                </FormControl>
            </VStack>
        </Container>
    );
}

export default ContactForm;
