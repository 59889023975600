import Header from '../partials/common/header/header';
import Hero from '../partials/home/hero';
import Footer from '../partials/common/footer/footer';
import Services from "../partials/home/services";
import Testimonials from "../partials/home/testimonials";
import ContactForm from "../partials/home/contactForm";
import Projects from "../partials/home/projects";

function Home() {
    return (
        <>
            <Header />
            <Hero />
            <Services />
            <Testimonials />
            <Projects />
            <ContactForm />
            <Footer />
        </>
    );
}

export default Home;
