import { Container, Heading, SimpleGrid } from '@chakra-ui/react';
import ServiceCard from "./serviceCard";

function Projects() {
    return (
        <Container py={20} px={{ base: 18, md: 28 }} width={"100%"} maxW={"100%"} centerContent background='brandBlack.300'>
            <Heading>Recent Projects</Heading>
            <SimpleGrid w='100%' pt={10} columns={{ base: 1, sm: 1, lg: 1 }} columnGap={10} rowGap={10}>
                <ServiceCard
                    name="DevConnect - Social Networking Platform"
                    description="DevConnect is a cutting-edge social networking platform that connects people based on their professional interests. The platform offers seamless user interactions, real-time messaging, and advanced privacy settings, providing a secure and engaging social experience for our clients."
                    imageSource={"https://plus.unsplash.com/premium_photo-1681487872232-fa622a6dd59e?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1160&q=80"}
                />
                <ServiceCard
                    name="E-Commerce Solutions App"
                    description="Our E-Commerce Solutions App revolutionizes the online shopping experience. With an intuitive user interface, personalized product recommendations, and a secure payment gateway, the app ensures higher customer retention and increased sales for businesses."
                    imageSource={"https://images.unsplash.com/photo-1460925895917-afdab827c52f?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1630&q=80"}
                />
                <ServiceCard
                    name="SmartHome Automation System"
                    description="Our SmartHome Automation System enables users to control their home devices remotely and intelligently. With voice command integration and AI-based learning algorithms, homeowners can optimize energy consumption, enhance security, and experience true smart living."
                    imageSource={"https://plus.unsplash.com/premium_photo-1663100617829-5e747d55ed34?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1740&q=80"}
                />
            </SimpleGrid>
        </Container>
    );
}

export default Projects;
